const WITH_EMAIL_KEY = "withEmail";
const WITH_EMAIL_ENABLED = 1;
const WITH_EMAIL_DISABLED = 0;
const REGEX_UPPERCASE_CHARS = /[A-Z]/;
const REGEX_LOWERCASE_CHARS = /[a-z]/;
const REGEX_DIGITS = /[0-9]/;

const partnerField = document.getElementById("user.attributes.partner");

if (partnerField) {
  // polyfill for `URLSearchParams(window.location.search);`
  const searchParams = window.location.search
    .split("&")
    .reduce(function (acc, item) {
      const nameAndValue = item.split("=");
      acc[nameAndValue[0]] = nameAndValue[1];

      return acc;
    }, {});
  const partnerValue = searchParams["partner"];

  if (partnerValue) {
    partnerField.value = decodeURIComponent(partnerValue);
  }
}

const passwordViewToggleSvg = {
  hidden: null,
  shown: null,
};

const passwordFields = document.getElementsByName("passwordInputGroup");
for (let index = 0; index < passwordFields.length; index++) {
  const element = passwordFields[index];

  const passwordHiddenViewIcon = element.querySelector(
    'svg[name="passwordEyeHidden"]'
  );
  if (passwordHiddenViewIcon) {
    passwordViewToggleSvg.hidden = passwordHiddenViewIcon.outerHTML;
    passwordHiddenViewIcon.onclick = togglePasswordView(element, false);
  }
  const passwordShowViewIcon = element.querySelector(
    'svg[name="passwordEyeShow"]'
  );
  if (passwordShowViewIcon) {
    passwordShowViewIcon.classList.remove("hidden");
    passwordViewToggleSvg.shown = passwordShowViewIcon.outerHTML;
    passwordShowViewIcon.remove();
  }

  function togglePasswordView(element, isShown) {
    return function () {
      if (isShown) {
        const passwordEyeShow = element.querySelector(
          'svg[name="passwordEyeShow"]'
        );
        if (passwordEyeShow) {
          passwordEyeShow.remove();
        }

        const passwordViewIcon = element.querySelector(
          'div[name="passwordViewIcon"]'
        );
        if (passwordViewIcon) {
          passwordViewIcon.innerHTML = passwordViewToggleSvg.hidden;
        }

        const passwordEyeHidden = element.querySelector(
          'svg[name="passwordEyeHidden"]'
        );
        if (passwordEyeHidden) {
          passwordEyeHidden.onclick = togglePasswordView(element, false);
        }

        const passwordField = element.querySelector("input");
        if (passwordField) {
          passwordField.setAttribute("type", "password");
        }
        return;
      }

      const passwordEyeHidden = element.querySelector(
        'svg[name="passwordEyeHidden"]'
      );
      if (passwordEyeHidden) {
        passwordEyeHidden.remove();
      }

      const passwordViewIcon = element.querySelector(
        'div[name="passwordViewIcon"]'
      );
      if (passwordViewIcon) {
        passwordViewIcon.innerHTML = passwordViewToggleSvg.shown;
      }

      const passwordEyeShow = element.querySelector(
        'svg[name="passwordEyeShow"]'
      );
      if (passwordEyeShow) {
        passwordEyeShow.onclick = togglePasswordView(element, true);
      }

      const passwordField = element.querySelector("input");
      if (passwordField) {
        passwordField.setAttribute("type", "text");
      }
    };
  }
}

const forms = document.getElementsByTagName("form");
for (let index = 0; index < forms.length; index++) {
  const form = forms[index];

  form.onsubmit = function (event) {
    const buttons = event.currentTarget.getElementsByTagName("button");
    for (let index = 0; index < buttons.length; index++) {
      const button = buttons[index];
      button.classList.add("disabled");
      const loadingIcons = button.getElementsByTagName("svg");
      if (loadingIcons.length > 0) {
        loadingIcons[0].classList.remove("hidden");
      }
    }
  };
}

const validPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(.*){8,}$/;
const updatePassword = document.getElementById("updatePassword");
const passwordStrength = document.querySelector(
  'div[data-passwordStrength="updatePassword"]'
);

if (updatePassword) {
  const passwordStrengthFirstNode = passwordStrength.firstElementChild;
  const passwordStrengthLastNode = passwordStrength.lastElementChild;

  updatePassword.addEventListener("input", function (event) {
    const value = event.currentTarget.value;
    const isValidPassword = !value ? undefined : validPasswordRegex.test(value);

    passwordStrengthFirstNode.classList.remove("bg-gray-200");
    passwordStrengthFirstNode.classList.remove("bg-red");
    passwordStrengthFirstNode.classList.remove("bg-green");
    passwordStrengthLastNode.classList.remove("bg-gray-200");
    passwordStrengthLastNode.classList.remove("bg-green");

    if (isValidPassword !== undefined) {
      passwordStrength.classList.remove("hidden");

      if (!isValidPassword) {
        passwordStrengthFirstNode.classList.add("bg-red");
        passwordStrengthLastNode.classList.add("bg-gray-200");
      } else {
        passwordStrengthFirstNode.classList.add("bg-green");
        passwordStrengthLastNode.classList.add("bg-green");
      }
    } else {
      passwordStrengthFirstNode.classList.add("bg-gray-200");
      passwordStrengthLastNode.classList.add("bg-gray-200");
    }
  });
}

const passwordField = document.querySelector("#passwordField");
const passwordConfirmField = document.querySelector(
  "#password-confirm-register"
);

if (passwordConfirmField && passwordField) {
  passwordField.onchange = function (event) {
    passwordConfirmField.value = event.target.value;
  };
}

function setEmailFromQueryParameter() {
  const urlParams = new URLSearchParams(window.location.search);
  const email = urlParams.get("email");
  const emailInput = document.querySelector("#kc-register-form #email");
  if (emailInput && email && email.length > 0) {
    emailInput.value = email;
  }
}

function checkFormState() {
  const withEmailBtn = document.getElementById("with-email-btn");

  if (withEmailBtn) {
    withEmailBtn.addEventListener("click", () => {
      const url = new URL(window.location.href);

      if (url.searchParams.has(WITH_EMAIL_KEY)) {
        url.searchParams.set(WITH_EMAIL_KEY, WITH_EMAIL_ENABLED);
      } else {
        url.searchParams.append(WITH_EMAIL_KEY, WITH_EMAIL_ENABLED);
      }

      history.replaceState({}, null, url);

      checkFormState();
    });
  } else {
    return;
  }

  const withEmail = window.location.search.includes(
    `${WITH_EMAIL_KEY}=${WITH_EMAIL_ENABLED}`
  );
  const loginOptions = document.getElementById("auth-options");
  if (loginOptions) {
    loginOptions.style.display = withEmail ? "none" : "block";
  }

  const withEmailForm =
    document.getElementById("kc-form-login") ??
    document.getElementById("kc-register-form");
  if (withEmailForm) {
    withEmailForm.style.display = withEmail ? "block" : "none";
  }
}

function containsUppercase(str) {
  return REGEX_UPPERCASE_CHARS.test(str);
}

function containsLowercase(str) {
  return REGEX_LOWERCASE_CHARS.test(str);
}

function containsNumber(str) {
  return REGEX_DIGITS.test(str);
}

function getIconSetter(errorIcon, successIcon) {
  return (isSuccess) => {
    if (isSuccess === false) {
      errorIcon.classList.remove("hidden");
      if (!successIcon.classList.contains("hidden")) {
        successIcon.classList.add("hidden");
      }
    } else if (isSuccess === true) {
      successIcon.classList.remove("hidden");
      if (!errorIcon.classList.contains("hidden")) {
        errorIcon.classList.add("hidden");
      }
    } else {
      if (!successIcon.classList.contains("hidden")) {
        successIcon.classList.add("hidden");
      }
      if (!errorIcon.classList.contains("hidden")) {
        errorIcon.classList.add("hidden");
      }
    }
  };
}

function validateIncludes(validationValue, value) {
  const conditions = validationValue.split(",");

  const invalid = conditions.some((condition) => {
    switch (condition) {
      case "[uppercase]":
        if (!containsUppercase(value)) {
          return true;
        }
        break;
      case "[lowercase]":
        if (!containsLowercase(value)) {
          return true;
        }
        break;
      case "[numbers]":
        if (!containsNumber(value)) {
          return true;
        }
        break;
      default:
        return false;
    }
  });

  setIcon(!invalid);

  if (invalid && !errorIcon.classList.contains("input-danger")) {
    event.currentTarget.classList.remove("input-danger");
  } else if (!invalid) {
    event.currentTarget.classList.add("input-danger");
  }
}

function runValidations() {
  const messages = document.querySelectorAll("p[validate]");

  for (let index = 0; index < messages.length; index += 1) {
    const element = messages[index];

    const inputId = element.getAttribute("data-input-id");
    if (!inputId) {
      continue;
    }

    const inputElm = document.getElementById(inputId);
    if (!inputElm) {
      continue;
    }

    const errorIcon = element.querySelector('[name="error-icon"]');
    const successIcon = element.querySelector('[name="success-icon"]');
    const setIcon = getIconSetter(errorIcon, successIcon);

    inputElm.addEventListener("keyup", (event) => {
      const validationType = element.getAttribute("data-validate-type");
      const validationValue = element.getAttribute("data-validate-value");

      switch (validationType) {
        case "length":
          const value = parseInt(validationValue, 10);
          setIcon(event.currentTarget.value.length >= value);
          break;
        case "includes":
          return validateIncludes(validationValue, event.currentTarget.value);
        default:
          return false;
      }
    });
  }
}

document.addEventListener("DOMContentLoaded", function () {
  setEmailFromQueryParameter();

  checkFormState();
  runValidations();

  const url = new URL(window.location.href);
  if (url.searchParams.get("execution") === "VERIFY_EMAIL") {
    const systemMessage = document.getElementById("system-message");
    systemMessage.classList.add("hidden");
  }

  const linkAccountBtn = document.getElementById("linkAccount");
  if (linkAccountBtn) {
    linkAccountBtn.click();
  }
});
